<template>
  <div>
    <div class="search">
      <!-- <div>
                <div class="a-inline-block">
                    <label for="">关键字</label><a-input v-model="key" size="large" placeholder="请输入编号或分类名称" />
                </div>
                <div class="a-inline-block">
                    <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
                    <a-button size="large" @click="reset">重置</a-button>
                </div>
            </div> -->
      <a-button class="addBtn" size="large" type="primary" @click="showAdd">添加轮播图</a-button>
      <a-button class="addBtn" size="large" type="primary" @click="delAll" style="text-align:right">批量删除</a-button>
    </div>
    <a-table :row-selection="rowSelection" :rowKey="record=> record.id" :columns="columns" :data-source="data"
      class="table" :pagination="pagination">
      <span slot="num" slot-scope="text, record, index">
        {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
      </span>
      <template slot="Img" slot-scope="text,record">
        <img :src="ajaxUrl+'/scrm/index/getFile?id='+record.img" style="width:200px">
      </template>
      <template slot="Action" slot-scope="text,record" style="display:flex;">
        <a class="action" href="javascript:;" @click="editType(record)">编辑</a>
        <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
          <a class="action del" href="javascript:;">删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal v-model="visible" title="添加轮播图" @ok="addType">
      <template>
        <img style="width:200px;display: block;" v-if="imageId" :src="ajaxUrl+'/scrm/index/getFile?id='+imageId">
        <label class="fileLabel" for="image">
          <a-icon type="upload" /> 上传轮播图
        </label>
        <input type="file" id="image" ref="image" style="display:none" @change="imgUpload($event)">
        <div style="padding-top:15px;color:#999;font-size:13px">建议尺寸 <span style="color:red">750x400像素</span></div>
      </template>
    </a-modal>
  </div>
</template>
<script>
// import $ from "jquery";
import {
  requestXml,
  getDateTime,
  fileUpload,
  ajaxUrl,
} from "../../../assets/js/request";
export default {
  data() {
    return {
      ajaxUrl: ajaxUrl,
      key: "",
      type: null,
      status: null,
      visible: false,
      onID: "", //编辑状态id
      columns: [
        {
          title: "序号",
          dataIndex: "num",
          width: 70,
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        // { title: '编号', dataIndex: 'id', key: '1'},
        {
          title: "分类图片",
          align: "center",
          key: "4",
          scopedSlots: { customRender: "Img" },
        },
        { title: "添加时间", align: "center", dataIndex: "created", key: "3" },
        {
          title: "操作",
          key: "operation",
          // fixed: 'right',
          align: "center",
          scopedSlots: { customRender: "Action" },
        },
      ],
      data: [],
      addText: "", //添加分类名称
      imageId: "",
      // 分页
      pagination: {
        current: 1,
        total: 100,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showQuickJumper: true,
        showTotal: total => `共 ${total} 条`, // 显示总数
        onChange: (current) => this.changePage(current), //点击当前页码
        onShowSizeChange: (current, pageSize) => {
          //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
          console.log(pageSize);
          this.showSizeChange(current, pageSize);
        },
      },
      selectedRows: "",
      rowSelection: {
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
          this.selectedRows = selectedRows;
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
          this.selectedRows = selectedRows;
        },
      },
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    getData() {
      requestXml(
        "/jieb/Apisetting/carousellist",
        "POST",
        (res) => {
          console.log(res);
          for (let i = 0; i < res.list.length; i++) {
            res.list[i].created = getDateTime(res.list[i].created);
          }
          this.pagination.total = Number(res.page.total);
          this.pagination.pageSize = Number(res.page.pagesize);
          this.data = res.list;
        },
        {
          page: {
            curpage: this.pagination.current,
            pagesize: this.pagination.pageSize,
          },
        }
      );
    },
    // 分页
    changePage(current) {
      //current参数表示是点击当前的页码，
      console.log(current);
      this.pagination.current = current;
      this.getData(); //向后端发送请求
    },
    showSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getData(); //向后端发送请求
    },
    //添加分类
    showAdd() {
      this.visible = true;
    },
    // 图片上传
    imgUpload(e) {
      fileUpload(e, (res) => {
        console.log(res);
        this.imageId = res.id;
        this.$refs.image.value = "";
      });
    },
    addType() {
      // console.log(this.addText);
      if (this.imageId != "") {
        let url = "/jieb/Apisetting/carouseledit";
        let data = { img: this.imageId };
        if (this.onID) {
          url = "/jieb/Apisetting/carouseledit";
          data.id = this.onID;
        }
        requestXml(
          url,
          "POST",
          (res) => {
            console.log(res);
            this.visible = false;
            this.onID = "";
            this.addText = "";
            this.imageId = "";
            this.getData();
          },
          data
        );
      } else {
        this.$message.info("请先上传图片");
      }
    },
    // 编辑
    editType(res) {
      this.onID = res.id;
      this.imageId = res.img;
      this.visible = true;
    },
    // 删除
    onDelete(id) {
      requestXml(
        "/jieb/Apisetting/carouseldel",
        "POST",
        () => {
          this.getData();
        },
        { id: id }
      );
    },
    delAll() {
      if (this.selectedRows.length < 1) {
        this.$message.info("请先选择");
        return false;
      } else {
        let idArr = [];
        let sel = this.selectedRows;
        for (let i = 0; i < sel.length; i++) {
          idArr.push(sel[i].id);
        }
        this.onDelete(idArr);
      }
    },
  },
};
</script>
<style scoped>
.search {
  display: flex;
  justify-content: space-between;
}
.addBtn {
  width: auto;
}
.action {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background: #dceeff;
  border: 1px solid #36a3ff;
  color: #36a3ff;
  border-radius: 5px;
}
.del {
  background: #ffdcdc;
  border-color: #ff4c4c;
  color: #ff4c4c;
}
.add_title {
  padding: 15px 0;
}
.add_inp {
  height: 40px;
  margin-bottom: 60px;
}
</style>

